import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/MainPages/LoginPage.vue";
import LandingPage from "../views/MainPages/LandingPage.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/LandingPage",
    name: "LandingPage",
    component: LandingPage,
    children: [
      {
        path: "/Dashboard",
        name: "Dashboard",
        component: () => import(/* webpackChunkName: "about" */ "../views/ChildPages/Dashboard.vue"),
      },
      {
        path: "/Banks",
        name: "Banks",
        component: () => import(/* webpackChunkName: "about" */ "../views/ChildPages/Banks.vue"),
      },
      {
        path: "/Surveys",
        name: "Surveys",
        component: () => import(/* webpackChunkName: "about" */ "../views/ChildPages/Surveys.vue"),
      },

      {
        path: "/ExchangeUsers",
        name: "ExchangeUsers",
        component: () => import(/* webpackChunkName: "about" */ "../views/ChildPages/ExchangeUsers.vue"),
      },
      {
        path: "/CommunityGroups",
        name: "CommunityGroups",
        component: () => import(/* webpackChunkName: "about" */ "../views/ChildPages/CommunityGroups.vue"),
      },
      {
        path: "/Insights",
        name: "Insights",
        component: () => import(/* webpackChunkName: "about" */ "../views/ChildPages/Insights.vue"),
      },
      {
        path: "/InsightCategories",
        name: "InsightCategories",
        component: () => import(/* webpackChunkName: "about" */ "../views/ChildPages/InsightCategories.vue"),
      },
      {
        path: "/Resources",
        name: "Resources",
        component: () => import(/* webpackChunkName: "about" */ "../views/ChildPages/Resources.vue"),
      },
      {
        path: "/Events",
        name: "Events",
        component: () => import(/* webpackChunkName: "about" */ "../views/ChildPages/Events.vue"),
      },
      {
        path: "/News",
        name: "News",
        component: () => import(/* webpackChunkName: "News" */ "../views/ChildPages/News.vue"),
      },
      {
        path: "/CommunityCorner",
        name: "CommunityCorner",
        component: () => import(/* webpackChunkName: "CommunityCorner" */ "../views/ChildPages/CommunityCorner.vue"),
      },
      {
        path: "/Users",
        name: "Users",
        component: () => import(/* webpackChunkName: "about" */ "../views/ChildPages/Users.vue"),
      },
      {
        path: "/DocumentVaultCategories",
        name: "DocumentVaultCategories",
        component: () => import(/* webpackChunkName: "about" */ "../views/ChildPages/DocumentVaultCategories.vue"),
      },
      {
        path: "/DocumentVault",
        name: "DocumentVault",
        component: () => import(/* webpackChunkName: "about" */ "../views/ChildPages/DocumentVault.vue"),
      },
      {
        path: "/Settings",
        name: "Settings",
        component: () => import(/* webpackChunkName: "about" */ "../views/ChildPages/Settings.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

